
import City from "@/store/interfaces/City";
import Postcode from "@/store/interfaces/Postcode";
import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store";

const birthdateRegex = new RegExp(
  /([0-9])([0-9][/\s-]?)([0-9])([0-9])[/\s-]?([1-2])([0-9])([0-9])([0-9])$/,
);
const emailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["data", "admin"]),
    listAssos() {
      return [
        ...this.data.associations,
        { id: -1, name: "Autre, précisez..." },
      ];
    },
    birthdate: {
      get() {
        return this.raw.birthdate;
      },
      set(value: string) {
        if (value.length < 11) {
          this.raw.birthdate = value;
          // check if valid formatting and replace data.birthdate
          if (birthdateRegex.test(value)) {
            // We try to parse it as a date
            const raw = value.replace(/[/\-\s]/g, "");
            const dateFields = {
              day: raw.slice(0, 2),
              month: raw.slice(2, 4),
              year: raw.slice(4),
            };
            try {
              const date = new Date(
                `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
              );
              if (!isNaN(date.getTime())) {
                this.raw.birthdate = `${dateFields.day}/${dateFields.month}/${dateFields.year}`;
                this.coupon.birthdate = `${dateFields.year}-${dateFields.month}-${dateFields.day}`;
              } else {
                throw { message: "Invalid date" };
              }
            } catch (error) {
              console.error(error.message);
            }
          }
        } else {
          this.raw.birthdate = this.raw.birthdate.slice(
            0,
            this.raw.birthdate.length - 1,
          );
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      async set(value: boolean) {
        if (!value) {
          this.coupon = { ...this.defaultCoupon };
          (this.raw = {
            birthdate: "",
            cities: [] as Array<City>,
          }),
            (this.files = {
              idDocument: null,
              familyRecord: null,
              proofOfAddress: null,
            });
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    loading: false,
    formValid: false,
    coupon: {
      firstname: "",
      lastname: "",
      birthdate: "" as string,
      address: "",
      placeOfBirth: "",
      phone: "",
      email: "",
      associationId: 0,
      associationOther: "",
      cityId: 0,
      postcodeId: 0,
    },
    defaultCoupon: {
      firstname: "",
      lastname: "",
      birthdate: "" as string,
      address: "",
      placeOfBirth: "",
      phone: "",
      email: "",
      associationId: 0,
      associationOther: "",
      cityId: 0,
      postcodeId: 0,
    },
    files: {
      idDocument: null,
      familyRecord: null,
      proofOfAddress: null,
    },
    raw: {
      birthdate: "",
      cities: [] as Array<City>,
    },
    rules: {
      files: [
        (val: File) =>
          (!!val &&
            (val.type === "image/png" ||
              val.type === "image/jpeg" ||
              val.type === "application/pdf")) ||
          !val ||
          "Le type de fichier est incorrect",
        (val: File) =>
          (!!val && val.size < 1.5 * 1024 * 1024) ||
          !val ||
          "Le fichier est trop volumineux",
      ],
      name: [
        (val: string) => !!val.length || "Entrez un nom",
        (val: string) => val.length > 1 || "Ce nom paraît court",
        (val: string) => val.length < 250 || "Ce nom est trop long",
      ],
      email: [
        (val: string) => !!val.length || "Veuillez entrer votre email",
        (val: string) => emailRegex.test(val) || "Cet email semble invalide",
      ],
      confirmEmail: (context: any) => [
        (val: string) =>
          (val &&
            context.coupon.email &&
            (context.coupon.email as string).toLowerCase() ===
              val.toLowerCase()) ||
          "L'email ne correspond pas",
      ],
      address: [(val: string) => !!val.length || "Entrez votre addresse"],
      placeOfBirth: [
        (val: string) =>
          !!val.length || "Entrez le lieu de naissance de l'enfant",
      ],
      birthdate: [
        (value: string) => {
          if (birthdateRegex.test(value)) {
            // We try to parse it as a date
            const raw = value.replace(/[/\-\s]/g, "");
            const dateFields = {
              day: raw.slice(0, 2),
              month: raw.slice(2, 4),
              year: raw.slice(4),
            };
            try {
              const date = new Date(
                `${dateFields.year}-${dateFields.month}-${dateFields.day}`,
              );
              return (
                !isNaN(date.getTime()) ||
                "Ce n'est pas une date de naissance valide"
              );
            } catch (error) {
              return "Ce n'est pas une date de naissance valide";
            }
          } else {
            return "Veuillez entrer une date de naissance complète";
          }
        },
      ],
      postcodes: [(val: number) => !!val || "Choisissez un code postal"],
      cities: [(val: number) => !!val || "Choisissez une ville"],
      associations: [
        (val: number) => !!val || val === -1 || "Choisissez une association",
      ],
      associationOther: (context: any) => [
        (val: string) =>
          !(context.coupon.associationId === -1 && !val) ||
          "Entrez le nom de l'association",
      ],
      phone: [
        (val: string) => !!val.length || "Entrez votre numéro de contact",
        (val: string) =>
          /0[1-9][-/.\s]?([0-9]{2}[-/.\s]?){4}$/.test(val) ||
          "Ce numéro de téléphone semble invalide",
      ],
      checkbox: [(val: boolean) => val || "Veuillez accepter les conditions"],
    },
  }),
  methods: {
    async sendCoupon() {
      if ((this.$refs.formCoupon as HTMLFormElement).validate()) {
        this.loading = true;

        // Preparing the JSON part of the FormData
        const object = {
          firstname: this.coupon.firstname,
          lastname: this.coupon.lastname,
          birthdate: this.coupon.birthdate,
          address: this.coupon.address,
          placeOfBirth: this.coupon.placeOfBirth,
          phone: this.coupon.phone,
          email: this.coupon.email,
          association_id: this.coupon.associationId,
          associationOther: this.coupon.associationOther,
          city_id: this.coupon.cityId,
          postcode_id: this.coupon.postcodeId,
        };
        // Setting the endpoint and headers
        const route = `${process.env.VUE_APP_API_HOST}/coupon/override`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${this.admin.token}`);
        const data = new FormData();

        // Adding the Files and object as "coupon"
        if (this.files.idDocument) {
          data.append("idDocument", this.files.idDocument as unknown as File);
        }
        if (this.files.familyRecord) {
          data.append(
            "familyRecord",
            this.files.familyRecord as unknown as File,
          );
        }
        if (this.files.proofOfAddress) {
          data.append(
            "proofOfAddress",
            this.files.proofOfAddress as unknown as File,
          );
        }
        data.append("coupon", JSON.stringify(object));
        try {
          const response = await fetch(route, {
            headers,
            method: "POST",
            body: data,
          });
          if (response.status === 200) {
            await store.dispatch("refreshCoupons");
            const object = await response.json();
            // We can reset the form
            this.coupon = { ...this.defaultCoupon };
            this.files = {
              idDocument: null,
              familyRecord: null,
              proofOfAddress: null,
            };
            this.raw = {
              birthdate: "",
              cities: [] as Array<City>,
            };
            (this.$refs.formCoupon as Record<string, any>).resetValidation();
            this.$emit("close");
          } else {
            if (response.status === 400) {
              this.$store.dispatch("showAlert", {
                message:
                  "Les données fournies posent problème, vérifiez et réessayez",
                color: "warning",
                timeout: 5000,
              });
            } else {
              this.$store.dispatch("showAlert", {
                message:
                  "Quelque chose s'est mal passé, veuillez réessayer ultérieurement",
                color: "error",
                timeout: 5000,
              });
            }
          }
          this.loading = false;
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message:
              "Quelque chose s'est mal passé, veuillez réessayer ultérieurement",
            color: "error",
            timeout: 5000,
          });
          this.loading = false;
        }
      }
    },
    updateCities(val: number) {
      // We filter get the postcode object from its ID :
      const postcode = (this.data.postcodes as Array<Postcode>).find(
        (value) => {
          return value.id === val;
        },
      );
      if (postcode && postcode.Cities) {
        this.raw.cities = postcode.Cities;
      }
    },
  },
  async beforeMount() {
    await store.dispatch("refreshPostcodes");
    await store.dispatch("refreshAssociations");
  },
});
