
import SupportingDocumentType from "@/store/interfaces/SupportingDocumentType";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      required: true,
    },
    couponId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(["admin"]),
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.documentData = null;
          this.documentDataUrl = null;
          this.$emit("close");
        }
      },
    },
    documentDataType() {
      if (this.documentData) {
        switch (this.documentData.type) {
          case "image/jpeg":
            return "image";
          case "image/png":
            return "image";
          case "application/pdf":
            return "pdf";
          default:
            return "unknown";
        }
      } else {
        return "unknown";
      }
    },
  },
  data: () => ({
    documentData: null as null | Blob,
    documentDataUrl: null as null | string,
    downloadLoading: false,
    deleteLoading: false,
  }),
  methods: {
    ...mapActions(["showAlert"]),
    downloadData() {
      if (this.documentDataUrl) {
        this.downloadLoading = true;
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = this.documentDataUrl;
        a.download = this.document.filename;
        a.click();
        this.downloadLoading = false;
      }
    },
    getIcon(type: SupportingDocumentType) {
      switch (type) {
        case 0:
          return "mdi-account";
        case 1:
          return "mdi-book";
        case 2:
          return "mdi-map-marker";
        default:
          return "mdi-help-circle";
      }
    },
    getTypeString(type: SupportingDocumentType) {
      switch (type) {
        case 0:
          return "Pièce d'identité";
        case 1:
          return "Livret de famille";
        case 2:
          return "Justificatif de domicile";
        default:
          return "Type Inconnu";
      }
    },
    async fetchDocumentData(id: number) {
      if (id) {
        const route = `${process.env.VUE_APP_API_HOST}/supportingdocument/${id}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${this.admin.token}`);
        const response = await fetch(route, {
          headers,
        });
        if (response.status === 200) {
          this.documentData = await response.blob();
          // we create the Data URL
          this.documentDataUrl = URL.createObjectURL(this.documentData);
        } else {
          this.showAlert({
            message: "Impossible de récupérer l'aperçu du document",
            color: "warning",
            timetout: 4000,
          });
        }
      }
    },
    async deleteDocument(id: number) {
      try {
        this.deleteLoading = true;
        const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.couponId}/supportingdocument/${id}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${this.admin.token}`);
        const response = await fetch(route, {
          method: "DELETE",
          headers,
        });
        if (response.status === 200) {
          this.$emit("refresh");
          this.showDialog = false;
        } else {
          this.showAlert({
            message: "Impossible de supprimer ce document pour le moment",
            color: "warning",
            timetout: 4000,
          });
        }
        this.deleteLoading = false;
      } catch (error) {
        this.deleteLoading = false;
        console.error(error);
      }
    },
  },
  beforeMount() {
    if (this.document) {
      this.fetchDocumentData(this.document.id);
    }
  },
  watch: {
    document: {
      deep: true,
      handler() {
        if (this.document) {
          // We fetch the document data
          this.fetchDocumentData(this.document.id);
        }
      },
    },
  },
});
