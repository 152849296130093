
import { CouponPreview } from "@/store/interfaces/Coupon";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import CouponTable from "@/components/CouponTable.vue";
import AddCouponDialog from "@/components/AddCouponDialog.vue";

export default Vue.extend({
  components: {
    CouponTable,
    AddCouponDialog,
  },
  computed: {
    ...mapState(["data", "admin"]),
    filteredValidCouponArray() {
      if (this.data.coupons) {
        return (this.data.coupons as Array<CouponPreview>).filter((value) => {
          return !value.deleted;
        });
      } else {
        return [];
      }
    },
    filteredInvalidCouponArray() {
      if (this.data.coupons) {
        return (this.data.coupons as Array<CouponPreview>).filter((value) => {
          return value.deleted;
        });
      } else {
        return [];
      }
    },
    panels() {
      return [
        {
          title: "Coupons générés",
          filteredArray: this.filteredValidCouponArray,
        },
        {
          title: "Coupons invalides",
          filteredArray: this.filteredInvalidCouponArray,
        },
      ];
    },
  },
  data: () => ({
    generateCouponDialog: false,
    open: [0, 1],
    csvLoading: false,
    csvDialog: false,
    filterBy: {
      association: false,
      city: false,
    },
    csvFilters: {
      associationId: 0,
      cityId: 0,
    },
  }),
  methods: {
    ...mapActions(["refreshCoupons", "refreshAssociations"]),
    async downloadCsv() {
      this.csvLoading = true;
      const filters = {
        ...(this.filterBy.association
          ? { association_id: this.csvFilters.associationId }
          : {}),
        ...(this.filterBy.city ? { city_id: this.csvFilters.cityId } : {}),
      };
      await this.$store.dispatch("getCsvData", filters);
      this.csvLoading = false;
    },
  },
  async beforeMount() {
    await this.$store.dispatch("refreshCoupons");
    await this.$store.dispatch("refreshPostcodes");
    await this.$store.dispatch("refreshAssociations");
    await this.$store.dispatch("refreshCities");
  },
});
