
import Association from "@/store/interfaces/Association";
import { CouponPreview } from "@/store/interfaces/Coupon";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { mapState } from "vuex";
import CouponDetailDialog from "@/components/CouponDetailDialog.vue";

export default Vue.extend({
  components: {
    CouponDetailDialog,
  },
  props: {
    filteredArray: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["data"]),
    filteredItems() {
      // We filter by id if filters.id not null
      let filter: Array<CouponPreview> = this.filteredArray.filter((value) => {
        if (this.filters.id) {
          return `${new Date(
            (value as CouponPreview).createdAt
          ).getFullYear()}-${(value as CouponPreview).index}`.includes(
            this.filters.id
          );
        } else {
          return true;
        }
      }) as Array<CouponPreview>;
      // Filter by City
      filter = filter.filter((value) => {
        if (this.filters.city) {
          return value.City.name
            .toLowerCase()
            .startsWith(this.filters.city.toLowerCase());
        }
        return true;
      }) as Array<CouponPreview>;
      // Filter by child
      filter = filter.filter((value) => {
        if (this.filters.child) {
          return value.name
            .toLowerCase()
            .includes(this.filters.child.toLowerCase());
        }
        return true;
      }) as Array<CouponPreview>;
      // Filter by Association
      filter = filter.filter((value) => {
        if (this.filters.asso) {
          return this.getAssociationName(
            value as CouponPreview,
            this.data.associations
          )
            .toLowerCase()
            .includes(this.filters.asso.toLowerCase());
        }
        return true;
      }) as Array<CouponPreview>;
      return filter;
    },
    globalAssociations() {
      const associations = [] as Array<Association>;
      for (const asso of this.$store.state.data.associations) {
        associations.push(asso);
      }
      const otherAssociations = [] as Array<Association>;
      if (this.data.coupons) {
        let i = 0;
        for (const coupon of this.data.coupons as Array<CouponPreview>) {
          if (coupon.associationOther) {
            // We need to check if the association isn't in database now :
            const match = (
              this.data.associations as Array<Association>
            ).findIndex((value: Association) => {
              return (
                value.name.toLowerCase() ==
                coupon.associationOther.toLowerCase()
              );
            });
            // If not found, we add it
            if (match == -1) {
              otherAssociations.push({
                name: coupon.associationOther,
                id: i,
              });
            }
          }
          i--;
        }
      }
      return [...associations, ...otherAssociations];
    },
  },
  data: () => ({
    headers: [
      {
        text: "Numéro",
        align: "start",
        value: "id",
        filterable: true,
      },
      {
        text: "Commune",
        align: "start",
        value: "City.name",
        filterable: true,
      },
      {
        text: "Enfant",
        align: "start",
        value: "name",
        filterable: true,
      },
      {
        text: "Association",
        align: "start",
        value: "association_id",
        filterable: true,
      },

      {
        text: "Détail",
        align: "end",
        value: "actions",
      },
    ] as Array<DataTableHeader>,
    filters: {
      id: "",
      city: "",
      child: "",
      asso: "",
    },
    viewedId: 0,
    showViewDialog: false,
  }),
  methods: {
    getAssociationName(
      coupon: CouponPreview,
      associations: Array<Association>
    ): string {
      const association = associations.find((value) => {
        return value.id === coupon.association_id;
      });
      if (association) {
        return association.name;
      } else {
        return coupon.associationOther;
      }
    },
    viewItem(item: CouponPreview) {
      this.viewedId = item.id;
      this.showViewDialog = true;
    },
    closeDialog() {
      this.viewedId = 0;
      this.showViewDialog = false;
    },
    filterItems(value: CouponPreview, search: string, item: CouponPreview) {
      if (!search) {
        return true;
      }
      return `2024-${item.id}`.includes(search);
    },
  },
});
