
import { CouponDetail } from "@/store/interfaces/Coupon";
import SupportingDocument from "@/store/interfaces/SupportingDocument";
import SupportingDocumentType from "@/store/interfaces/SupportingDocumentType";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import DocumentViewer from "@/components/DocumentViewer.vue";

export default Vue.extend({
  components: {
    DocumentViewer,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["admin"]),
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.coupon = null;
          this.edit = false;
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    coupon: null as null | CouponDetail,
    edit: false,
    editBuffer: null as null | Record<string, any>,
    editLoading: false,
    viewedDocument: null as null | SupportingDocument,
    showViewerDialog: false,
    invalidLoading: false,
    gpdrLoading: false,
    downloadCouponLoading: false,
  }),
  watch: {
    id() {
      if (this.id) {
        this.getCouponDetail(this.id);
      }
    },
  },
  beforeMount() {
    if (this.id) {
      this.getCouponDetail(this.id);
    }
  },
  methods: {
    ...mapActions(["showAlert", "refreshCoupons"]),
    async updateCoupon() {
      try {
        this.editLoading = true;
        const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.id}`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${this.admin.token}`);
        headers.set("Content-Type", "application/json");
        const response = await fetch(route, {
          headers,
          method: "PUT",
          body: JSON.stringify(this.editBuffer),
        });
        if (response.status === 200) {
          await this.refreshCoupons();
          this.showDialog = false;
        } else {
          this.showAlert({
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.editLoading = false;
        this.edit = false;
      }
    },
    editCoupon() {
      this.editBuffer = {
        deleted: this.coupon?.deleted,
        lastname: this.coupon?.lastname,
        firstname: this.coupon?.firstname,
        birthdate: this.coupon?.birthdate,
        placeOfBirth: this.coupon?.placeOfBirth,
        address: this.coupon?.address,
        postcode_id: this.coupon?.postcode_id,
        email: this.coupon?.email,
        association_id: this.coupon?.association_id,
        city_id: this.coupon?.city_id,
        phone: this.coupon?.phone,
      };
      this.edit = true;
    },
    async renderInvalid(id: number) {
      try {
        if (this.coupon) {
          this.invalidLoading = true;
          const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.id}`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${this.admin.token}`);
          headers.set("Content-Type", "application/json");
          const response = await fetch(route, {
            headers,
            method: "PUT",
            body: JSON.stringify({
              deleted: !this.coupon.deleted,
            }),
          });
          if (response.status === 200) {
            await this.refreshCoupons();
            this.showDialog = false;
          } else {
            this.showAlert({
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
        }

        this.invalidLoading = false;
      } catch (error) {
        this.invalidLoading = false;
        console.error(error);
      }
    },
    async gpdrDelete(id: number) {
      try {
        this.gpdrLoading = true;
        const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.id}/gpdr`;
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${this.admin.token}`);
        const response = await fetch(route, {
          headers,
          method: "DELETE",
        });
        if (response.status === 200) {
          await this.refreshCoupons();
          this.showDialog = false;
        } else {
          this.showAlert({
            message: "Impossible de récupérer les infos serveur pour le moment",
            color: "warning",
            timeout: 4000,
          });
        }
        this.gpdrLoading = false;
      } catch (error) {
        this.gpdrLoading = false;
        console.error(error);
      }
    },
    async downloadCoupon(uuid: string) {
      if (this.coupon) {
        try {
          this.downloadCouponLoading = true;
          const route = `${process.env.VUE_APP_API_HOST}/coupon/${this.coupon.uuid}`;
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${this.admin.token}`);
          const response = await fetch(route, {
            method: "GET",
            headers,
          });
          if (response.status === 200) {
            const couponData = await response.blob();
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = URL.createObjectURL(couponData);
            a.download = `${this.coupon.lastname}-${
              this.coupon.firstname
            }-${this.getAssociationName()}.pdf`;
            a.click();
          } else {
            this.showAlert({
              message:
                "Impossible de récupérer les infos serveur pour le moment",
              color: "warning",
              timeout: 4000,
            });
          }
          this.downloadCouponLoading = false;
        } catch (error) {
          console.error(error);
          this.downloadCouponLoading = false;
        }
      }
    },
    openDocumentViewer(document: SupportingDocument) {
      this.viewedDocument = document;
      this.showViewerDialog = true;
    },
    closeDocumentViewer() {
      this.viewedDocument = null;
      this.showViewerDialog = false;
    },
    getIcon(type: SupportingDocumentType) {
      switch (type) {
        case 0:
          return "mdi-account";
        case 1:
          return "mdi-book";
        case 2:
          return "mdi-map-marker";
        default:
          return "mdi-help-circle";
      }
    },
    getTypeString(type: SupportingDocumentType) {
      switch (type) {
        case 0:
          return "Pièce d'identité";
        case 1:
          return "Livret de famille";
        case 2:
          return "Justificatif de domicile";
        default:
          return "Type Inconnu";
      }
    },
    formatDate(rawDate: string) {
      const dateSplit = rawDate.split("-");
      if (dateSplit.length === 3) {
        return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
      } else {
        return "Date invalide";
      }
    },
    getAssociationName() {
      if (this.coupon && this.coupon.Association) {
        return this.coupon.Association.name;
      } else if (this.coupon && this.coupon.associationOther) {
        return this.coupon.associationOther;
      } else {
        return "Association introuvable";
      }
    },
    refresh() {
      this.coupon = null;
      this.getCouponDetail(this.id);
    },
    async getCouponDetail(id: number) {
      const route = `${process.env.VUE_APP_API_HOST}/coupon/detail/${id}`;
      const headers = new Headers();
      headers.set("Authorization", `Bearer ${this.admin.token}`);

      const response = await fetch(route, {
        headers,
      });
      if (!(response.status === 200)) {
        this.showAlert({
          message: "Impossible de récupérer le coupon pour le moment",
          color: "error",
          timetout: 4000,
        });
        setTimeout(() => {
          this.$emit("close");
        }, 3000);
      } else {
        this.coupon = await response.json();
      }
    },
  },
});
